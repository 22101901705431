import React from "react"
import ProductPage from "../../layouts/product-page"

const model = {
  eng: {
    id: "spot",
    model: "Mobile WiFi",
    os: "N/A",
    chipset: "Qualcomm MDM9207",
    band:
      "GSM 850/900/1800/1900, WCDMA 1/2/4/5/8, FDD LTE 1/2/3/4/5/7/8/12/13/17/20/28",
    display: "LED light (Signal, WiFi, SMS, Battery)",
    speed: "FDD-LTE DL 150Mbps/UL 50Mbps",
    memory: "258MB NAND FLASH+128MB LPDDR2（Micro SD card up to 32GB",
    dimensions: "104*64*12.9mm，87g",
    cameras: "N/A",
    video: "N/A",
    audio: "N/A",
    connectivity: "WiFi 802.11 b/g/n, 2.4G, 2X2, support up to 15 WiFi users",
    sensor: "G-sensor, Proximity Sensor, Light Sensor",
    interface: "3FF SIM Card, Micro USB 2.0",
    battery: "1800mAh Li-ion battery",
    cmf: "Raw material",
    // certifications: "CE, FCC, GCF, RoHS, WEEE,REACH",
    accessories: "Default: Charger 5V/1A, USB cable, QG, PSI",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3e_vVgcUjuwDlcwn-Ngo9n2wpADa1PaibFo2sEc7eIwD5YM7tSIyWN-PDKI4HG7cfPUmbi1O3kd8Lko9DnLzoomy5T_L-qg6DM2d_jQSEFJSxjrdfwm-19xVpLTIznFaGqAKdSB4p1nu6SjT0P8r3c=d",
  },
  esp: {
    id: "spot",
    model: "Mobile WiFi",
    os: "N/A",
    chipset: "Qualcomm MDM9207",
    band:
      "GSM 850/900/1800/1900, WCDMA 1/2/4/5/8, FDD LTE 1/2/3/4/5/7/8/12/13/17/20/28",
    display: "LED light (Signal, WiFi, SMS, Battery)",
    speed: "FDD-LTE DL 150Mbps/UL 50Mbps",
    memory: "258MB NAND FLASH+128MB LPDDR2（Micro SD card up to 32GB",
    dimensions: "104*64*12.9mm，87g",
    cameras: "N/A",
    video: "N/A",
    audio: "N/A",
    connectivity: "WiFi 802.11 b/g/n, 2.4G, 2X2, support up to 15 WiFi users",
    sensor: "G-sensor, Proximity Sensor, Light Sensor",
    interface: "3FF SIM Card, Micro USB 2.0",
    battery: "1800mAh Li-ion battery",
    cmf: "Raw material",
    // certifications: "CE, FCC, GCF, RoHS, WEEE,REACH",
    accessories: "Default: Charger 5V/1A, USB cable, QG, PSI",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3e_vVgcUjuwDlcwn-Ngo9n2wpADa1PaibFo2sEc7eIwD5YM7tSIyWN-PDKI4HG7cfPUmbi1O3kd8Lko9DnLzoomy5T_L-qg6DM2d_jQSEFJSxjrdfwm-19xVpLTIznFaGqAKdSB4p1nu6SjT0P8r3c=d",
  },
}
export default () => <ProductPage model={model} />
